<template>
  <div class="home">
    <el-form :model="form" label-width="90px">
      <el-form-item label="微信名称：">
        <el-input v-model="form.wxName" placeholder="请输入微信名称"></el-input>
      </el-form-item>
      <el-form-item label="聊天信息：">
        <el-input
          v-model="form.message"
          placeholder="请输入聊天信息"
          type="textarea"
          :rows="4"
        ></el-input>
      </el-form-item>
      <el-form-item label="时间：">
        <el-time-picker
          arrow-control
          v-model="form.time"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
          }"
          placeholder="任意时间点"
          value-format="HH:mm:ss"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item label="头像：">
        <el-input v-model="form.avatar" v-if="false"></el-input>
        <el-upload
          class="avatar-uploader"
          ref="upload"
          :show-file-list="false"
          action="/index/upload"
          :before-upload="beforeUpload"
          :on-change="handleChange"
          :auto-upload="false"
        >
          <img v-if="form.avatar" :src="form.avatar" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item class="submitItem">
        <el-button type="success" @click="submitForm" class="submitBtn"
          >添加</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { nanoid } from "nanoid";

export default {
  name: "EditMessage",
  data() {
    return {
      form: {
        id: "",
        wxName: "",
        message: "",
        time: "",
        avatar: "",
      },
      chatID: "",
    };
  },
  methods: {
    submitForm() {
      if (this.form.id === "") {
        this.form.id = nanoid();

        console.log("form: ", this.form);
        this.$axios({
          method: "post",
          url: `https://wxchat.yibaosw.cn/api/addMessage`,
          data: {
            data: this.form,
            id: this.chatID,
          },
        }).then((res) => {
          console.log(res);
          this.$bus.$emit("EditMessage");
          this.$router.push({
            path: "/MessageView",
            query: {
              id: this.chatID,
              isEditable: true,
            },
          });
        });
      } else {
        this.$axios({
          method: "post",
          url: `https://wxchat.yibaosw.cn/api/editMessage`,
          data: {
            data: this.form,
            chatID: this.chatID,
            MessageID: this.form.id,
          },
        }).then((res) => {
          console.log(res);
          this.$bus.$emit("EditMessage");
          this.$router.push({
            path: "/MessageView",
            query: {
              id: this.chatID,
              isEditable: true,
            },
          });
        });
      }
    },
    beforeUpload(file) {
      console.log("file: ", file);
      return true;
    },
    handleChange(file, fileList) {
      const that = this;
      console.log("file: ", file);
      console.log("fileList: ", fileList);

      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function () {
        that.form.avatar = reader.result;
      };
    },
  },
  mounted() {
    if (this.$route.query.type === "Edit") {
      // 编辑旧消息
      this.chatID = this.$route.query.chatID;
      this.form = this.$route.query.data;
    } else if (this.$route.query.type === "Add") {
      // 新增消息
      this.chatID = this.$route.query.chatID;
    }
  },
};
</script>


<style scoped lang="scss">
.home {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;

  .el-form {
    width: 90%;
    margin-top: 2rem;

    .submitItem {
      display: flex;
      justify-content: center;
    }

    .submitItem /deep/ .el-form-item__content {
      margin-top: 2rem;
      margin-left: 0 !important;
      width: 80%;

      .submitBtn {
        width: 100%;
        height: 100%;
      }
    }
  }
}

input[type="file"] {
  display: none;
}

.avatar-uploader,
.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader,
.el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader {
  width: 178px;
  height: 178px;
}
</style>