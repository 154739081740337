<template>
  <div class="home">
    <div class="top">
      <hr class="top__left" />
      <span class="top__date" v-if="chatData != null">{{ chatData.date }}</span>
      <hr class="top__right" />
    </div>
    <div class="content" v-if="chatData != null">
      <div class="message" v-for="(item, index) in chatData.chat" :key="index">
        <div class="message__top">
          <div class="message__top__avatar">
            <img :src="item.avatar" alt=" " v-if="item.avatar != ''" />
            <div class="placeholder" v-if="item.avatar === ''"></div>
          </div>
          <div class="message__top__content">
            <div class="message__top__content__top">
              <div class="message__top__content__top__name">
                {{ item.wxName }}
              </div>
              <div class="message__top__content__top__time">
                {{ item.time }}
              </div>
            </div>
            <div class="message__top__content__bottom">
              {{ item.message }}
            </div>
          </div>
        </div>
        <div class="btns" v-if="isEditable">
          <span class="btns__span1" @click="Edit(item.id)">修改</span>
          <span class="btns__span2" @click="toTop(item.id)">置顶</span>
          <span class="btns__span3" @click="Remove(item.id)">删除</span>
        </div>
        <div class="message__bottom" v-if="index != chatData.chat.length - 1">
          <hr class="message__bottom__hr" />
        </div>
        <div class="hrBox" v-if="index === chatData.chat.length - 1">
          <hr class="hrBox__hr1" />
          <div
            class="status-point"
            style="background-color: rgb(230, 230, 230)"
          />
          <hr class="hrBox__hr3" />
        </div>
      </div>
    </div>
    <div class="bottom" v-if="isEditable">
      <el-button type="primary" @click="AddMessage">添加新的聊天记录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageView",
  data() {
    return {
      chatID: "",
      chatData: null,
      isEditable: false,
      loading: null,
    };
  },
  methods: {
    GetData() {
      this.$axios({
        method: "GET",
        url: `https://wxchat.yibaosw.cn/api/getChat?id=${this.chatID}`,
      }).then(({ data }) => {
        this.chatData = data.data;
      });
    },
    Edit(id) {
      console.log("Edit");
      var chat = null;
      for (let index = 0; index < this.chatData.chat.length; index++) {
        if (this.chatData.chat[index].id == id) {
          chat = this.chatData.chat[index];
        }
      }
      this.$router.push({
        name: "EditMessage",
        query: { chatID: this.chatID, data: chat, type: "Edit" },
      });
    },
    toTop(id) {
      console.log("toTop");
      var i = 0;
      for (let index = 0; index < this.chatData.chat.length; index++) {
        if (this.chatData.chat[index].id == id) {
          i = index;
          break;
        }
      }
      const tmp = this.chatData.chat[i];
      this.chatData.chat.splice(i, 1);
      this.chatData.chat.unshift(tmp);
      // 更新
      this.$axios({
        method: "POST",
        url: `https://wxchat.yibaosw.cn/api/editChat`,
        data: {
          data: this.chatData,
          id: this.chatID,
        },
      }).then((e) => {
        console.log(e);
      });
    },
    Remove(id) {
      console.log("Remove");
      var i = 0;
      for (let index = 0; index < this.chatData.chat.length; index++) {
        if (this.chatData.chat[index].id == id) {
          i = index;
          break;
        }
      }
      this.chatData.chat.splice(i, 1);
      // 更新
      this.$axios({
        method: "POST",
        url: `https://wxchat.yibaosw.cn/api/editChat`,
        data: {
          data: this.chatData,
          id: this.chatID,
        },
      }).then((e) => {
        console.log(e);
      });
    },
    AddMessage() {
      this.$router.push({
        name: "EditMessage",
        query: { chatID: this.chatID, type: "Add" },
      });
    },
    share() {
      this.$axios({
        method: "GET",
        url: "https://wxchat.yibaosw.cn/api/getSign?url=https://wxchat.yibaosw.cn/",
      }).then(({ data }) => {
        console.log(data);
        //微信js-sdk配置参数
        const config = {
          // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出
          debug: false,
          appId: "wxba20b874e9ef0f9f", // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "onMenuShareAppMessage"], // 必填，需要使用的JS接口列表
        };
        //配置SDK
        this.$wx.config(config);

        const that = this;
        this.$wx.ready(function () {
          console.log("js-sdk配置成功！");
          //分享给好友
          that.$wx.updateAppMessageShareData({
            title: that.chatData.title, // 分享标题
            desc: that.GetDesc(), // 分享描述
            link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: "https://wxchat.yibaosw.cn/img.png", // 分享后显示的封面图
            success: function (e) {
              console.log(e);
            }, // 设置成功回调
          });
        });

        this.$wx.error(function (res) {
          console.log("config信息验证失败", res);
        });
      });
    },
    GetDesc() {
      var desc = "";
      for (let index = 0; index < this.chatData.chat.length; index++) {
        if (index == 2) {
          break;
        }
        var e = this.chatData.chat[index];
        var msg = e.wxName + ":" + e.message.substring(0, 10);
        desc += msg + "\n";
      }
      return desc + "聊天记录";
    },
    ChangeFontSize() {
      const fontSize = this.chatData.fontSize;
      if (fontSize === "小") {
        console.log("小");
        document
          .getElementsByClassName("home")[0]
          .style.setProperty("--font-size", "12px");
      } else if (fontSize === "中" && fontSize === "中（默认）") {
        console.log("中");
        document
          .getElementsByClassName("home")[0]
          .style.setProperty("--font-size", "16px");
      } else if (fontSize === "大") {
        console.log("大");
        document
          .getElementsByClassName("home")[0]
          .style.setProperty("--font-size", "20px");
      }
    },
  },
  mounted() {
    this.loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });

    const that = this;
    this.chatID = this.$route.query.id;
    this.isEditable = JSON.parse(this.$route.query.isEditable);
    this.GetData();

    this.$bus.$on("EditMessage", () => {
      that.GetData();
    });
  },
  watch: {
    chatData(newValue) {
      if (newValue != null) {
        this.loading.close();
        // 设置网页标题
        document.title = this.chatData.title;
        this.share();
        this.ChangeFontSize();
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.home {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.2rem;

    span {
      color: rgb(174, 174, 174);
      margin: 0 1rem;
    }

    &__right,
    &__left {
      display: inline-block;
      flex-grow: 2;
      border: 0.5px solid rgb(229, 229, 229);
    }

    &__left {
      margin-left: 1rem;
    }

    &__right {
      margin-right: 1rem;
    }
  }

  .content {
    flex-grow: 2;

    .message {
      //   position: relative;

      &__top {
        display: flex;
        justify-content: space-between;
        margin: 0.5rem 0;

        &__avatar {
          padding-left: 1rem;
          img {
            width: 3rem;
            height: 3rem;
            border-radius: 6px;
            vertical-align: middle;
          }

          .placeholder {
            width: 3rem;
            height: 3rem;
          }
        }

        &__content {
          flex-grow: 2;
          padding-left: 1rem;

          &__top {
            font-size: 14px;
            color: rgb(174, 174, 174);
            display: flex;
            justify-content: space-between;

            &__time {
              margin-right: 1rem;
            }
          }

          &__bottom {
            word-wrap: break-word;
            word-break: break-all;
            text-align: left;
            margin-right: 1.5rem;
            margin-top: 0.2rem;
            font-size: var(--font-size);
          }
        }

        &__time {
          font-size: 12px;
          color: rgb(174, 174, 174);
          padding-right: 1rem;
        }
      }

      &__bottom {
        display: flex;
        width: 100%;
        padding: 0.5rem 1rem 0.5rem 5rem;
        box-sizing: border-box;
        &__hr {
          display: inline-block;
          width: 100%;
          border: 0.5px solid rgb(229, 229, 229);
        }
      }

      .hrBox {
        display: flex;
        justify-content: space-between;
        padding-top: 0.5rem;

        &__hr1,
        &__hr3 {
          display: inline-block;
          border: 0.5px solid rgb(229, 229, 229);
          height: 0;
          width: 12rem;
          margin: 0 1rem;
        }

        .status-point {
          display: inline-block;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          //   margin: 0 0.5rem;
        }
      }

      .btns {
        // position: absolute;
        top: 1rem;
        right: 0.5rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 12px;
        margin: 0.5rem 0 0.5rem 0;

        &__span1,
        &__span2,
        &__span3 {
          margin: 0 1rem;
          color: blue;
        }
      }
    }
  }

  .bottom {
    margin: 0 auto;
    margin-bottom: 2rem;
  }
}
</style>
