import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'

// 引入VueRouter
import VueRouter from 'vue-router'
// 引入路由器
import router from './router'

// 引入ElementUI及其样式
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 引入微信JS-SDK
import jssdk from "weixin-js-sdk"

//将sdk挂载到原型
Vue.prototype.$wx = jssdk

// 挂载axios
Vue.prototype.$axios = axios

Vue.config.productionTip = false

// 应用Router
Vue.use(VueRouter)

// 应用ElementUI
Vue.use(ElementUI)

new Vue({
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount('#app')
