<template>
  <div class="home">
    <div class="top">
      <span>转发：点击分享，然后点击右上角（...）转发给朋友！</span>
    </div>
    <div class="mid">
      <div class="Chats" v-for="(item, index) in Chats" :key="index">
        <el-card class="box-card" :body-style="{ padding: '0.75rem' }">
          <div slot="header" class="clearfix">
            <span>{{ item.title }}</span>
            <div class="box-card__btns">
              <el-button
                type="danger"
                size="mini"
                round
                @click="RemoveChat(item.id)"
                >删除</el-button
              >
              <el-button
                type="success"
                size="mini"
                round
                @click="EditChat(item.id, item.title, item.date, item.fontSize)"
                >编辑</el-button
              >
            </div>
          </div>
          <div class="box-card__top">
            <span>时间：{{ item.date }}</span>
            <svg
              t="1699441714623"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="4053"
              width="1.5rem"
              height="1.5rem"
              @click="EditMessage(item.id)"
            >
              <path
                d="M358.165868 554.624796c-0.533143 0.680499-1.066285 1.391696-1.303692 2.251274l-41.104163 150.700257c-2.400676 8.772804 0.059352 18.226107 6.550183 24.892947 4.860704 4.742001 11.261485 7.350408 18.077727 7.350408 2.252297 0 4.504594-0.267083 6.727215-0.860601l149.630902-40.808428c0.23843 0 0.357134 0.207731 0.534166 0.207731 1.718131 0 3.408633-0.62217 4.683672-1.927909l400.113747-400.054395c11.883655-11.897981 18.404162-28.109198 18.404162-45.74281 0-19.989263-8.476045-39.963177-23.324218-54.767348l-37.786605-37.844933c-14.81645-14.848173-34.822087-23.338544-54.797024-23.338544-17.631566 0-33.842783 6.520507-45.75816 18.388812L358.758362 553.232077C358.344946 553.615816 358.462626 554.179658 358.165868 554.624796M862.924953 257.19778l-39.742143 39.71349-64.428382-65.451688 39.180348-39.179324c6.193049-6.222725 18.194384-5.318122 25.308409 1.822508l37.813211 37.845956c3.943822 3.941775 6.195096 9.18622 6.195096 14.372336C867.223862 250.574942 865.710392 254.42769 862.924953 257.19778M429.322487 560.907896l288.712541-288.728914 64.459081 65.49569L494.314711 625.838721 429.322487 560.907896zM376.718409 677.970032l20.863167-76.580143 55.656601 55.657624L376.718409 677.970032z"
                fill="#231F20"
                p-id="4054"
              ></path>
              <path
                d="M888.265084 415.735539c-15.144932 0-27.562752 12.313443-27.620058 27.665083l0 372.98283c0 19.559475-15.885805 35.444257-35.475979 35.444257L194.220958 851.827709c-19.559475 0-35.504632-15.883759-35.504632-35.444257L158.716326 207.602222c0-19.575848 15.945157-35.474956 35.504632-35.474956l406.367171 0c15.231913 0 27.592428-12.371772 27.592428-27.606755 0-15.202237-12.360516-27.590382-27.592428-27.590382L190.013123 116.930129c-47.684022 0-86.49291 38.779212-86.49291 86.49291L103.520213 820.59231c0 47.713698 38.808888 86.47756 86.49291 86.47756l639.334083 0c47.715745 0 86.509283-38.763862 86.509283-86.47756L915.856489 443.222567C915.794068 428.048983 903.408993 415.735539 888.265084 415.735539"
                fill="#231F20"
                p-id="4055"
              ></path>
            </svg>
          </div>
          <div class="box-card__bottom">
            <el-button type="warning" round size="mini" @click="share(item.id)"
              >分享聊天</el-button
            >
          </div>
        </el-card>
      </div>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="toEditChat()"
        >添加新的聊天记录</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      Chats: [],
    };
  },
  methods: {
    toEditChat() {
      this.$router.push("/EditChat");
    },
    GetRouterQuery() {
      this.Chats.push({
        id: 1,
        title: this.$route.params.title,
        date: this.$route.params.date,
        fontSize: this.$route.params.fontSize,
      });
    },
    GetChat() {
      this.Chats = [];

      this.$axios({
        method: "get",
        url: "https://wxchat.yibaosw.cn/api/getChats",
      }).then(({ data }) => {
        data.forEach((element) => {
          this.Chats.push({
            title: element.title,
            date: element.date,
            fontSize: element.fontSize,
            id: element._id,
          });
        });
      });
    },
    RemoveChat(id) {
      this.$axios({
        method: "get",
        url: `https://wxchat.yibaosw.cn/api/removeChat?id=${id}`,
      }).then((res) => {
        this.GetChat();
        console.log(res);
      });
    },
    EditChat(id, title, date, fontSize) {
      this.$router.push({
        path: "/EditChat",
        query: {
          id,
          title,
          date,
          fontSize,
        },
      });
    },
    EditMessage(id) {
      this.$router.push({
        path: "/MessageView",
        query: {
          id: id,
          isEditable: true,
        },
      });
    },
    share(id) {
      this.$router.push({
        path: "/MessageView",
        query: {
          id: id,
          isEditable: false,
        },
      });
    },
  },
  mounted() {
    document.title = "主页";

    const that = this;

    this.GetChat();

    this.$bus.$on("editChat", function () {
      that.GetChat();
    });
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh);
  --font-size: 16px;

  // position: relative;

  .top {
    width: 100%;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mid {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;

    flex-grow: 2;

    .Chats {
      width: 90%;
      margin-top: 1rem;

      .clearfix {
        display: flex;
        justify-content: space-between;
      }

      .box-card__top {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .bottom {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.el-card__header {
  padding: 0;
}
</style>
