import VueRouter from "vue-router"

import HomeView from "../pages/HomeView.vue";
import EditChat from '../pages/EditChat.vue'
import MessageView from "../pages/MessageView.vue";
import EditMessage from "../pages/EditMessage.vue";

const router = new VueRouter({
    routes: [
        // 主页面
        {
            path: '/',
            component: HomeView,
            name: 'HomeView'
        },
        // 编辑Chat界面
        {
            path: '/EditChat',
            component: EditChat,
            name: 'EditChat'
        },
        // 聊天记录页
        {
            path: '/MessageView',
            component: MessageView,
            name: 'MessageView'
        },
        // 编辑聊天内容界面
        {
            path: '/EditMessage',
            component: EditMessage,
            name: 'EditMessage'
        }

    ],
})

export default router