<template>
  <div class="home">
    <div class="formBox">
      <el-form :model="form" label-position="left" label-width="80px">
        <el-form-item label="标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            v-model="form.date"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="字体大小">
          <el-select v-model="form.fontSize" placeholder="请选择字体大小">
            <el-option label="大" value="大"></el-option>
            <el-option label="中（默认）" value="中"></el-option>
            <el-option label="小" value="小"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="btnBox">
      <el-button type="success" style="width: 100%" @click="EditAddChat()"
        >确认</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "EditChat",
  data() {
    return {
      form: {
        title: "",
        date: "",
        fontSize: "中（默认）",
      },
      chatID: "",
    };
  },
  methods: {
    EditAddChat() {
      if (this.chatID === "") {
        // 新记录插入
        this.AddChat();
      } else {
        // 旧纪录更新
        this.EditChat();
      }
    },
    AddChat() {
      this.$axios({
        method: "post",
        url: "https://wxchat.yibaosw.cn/api/addChat",
        data: this.form,
      })
        .then((res) => {
          console.log(res);
          this.$bus.$emit("editChat");
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
          alert("Error");
        });
    },
    EditChat() {
      this.$axios({
        method: "post",
        url: "https://wxchat.yibaosw.cn/api/editChat",
        data: {
          data: this.form,
          id: this.chatID,
        },
      })
        .then((res) => {
          console.log(res);
          this.$bus.$emit("editChat");
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
          alert("Error");
        });
    },
  },
  mounted() {
    if (JSON.stringify(this.$route.query) === "{}") {
      return;
    }

    const { id, title, date, fontSize } = this.$route.query;
    this.chatID = id;
    this.form.title = title;
    this.form.date = date;
    this.form.fontSize = fontSize;
  },
};
</script>

<style scoped lang="scss">
.home {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .formBox {
    margin-top: 3rem;
    width: 90vw;
  }

  .btnBox {
    margin-bottom: 3rem;
    width: 60%;
  }
}
</style>